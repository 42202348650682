import React from 'react';

const Contact = () => {
  const adresseEmail = "alandaunay1@gmail.com";
  const numeroTelephone = "(+1) 514 980-7949";
  const lienLinkedin = "https://www.linkedin.com/in/alan-daunay-895a881b8/";

  return (
    <div className="Page contact">
      <div>
      <img src="/illustrationordi.jpg" alt="Représentation d'un personnage" className="photo-rond"/>
      </div>
      <div className="coordonnees">
        <div className="coordonnee">
          <img src="/mail.jpg" alt="Logo Email" />
          <h3>Email</h3>
          <p>{adresseEmail}</p>
        </div>
        <div className="coordonnee">
          <img src="/phone.jpg" alt="Logo Téléphone" />
          <h3>Téléphone</h3>
          <p>{numeroTelephone}</p>
        </div>
        <div className="coordonnee">
          <img src="/linked.png" alt="Logo LinkedIn" />
          <h3>Linkedin</h3>
          <p>
            <a href={lienLinkedin} target="_blank" rel="noopener noreferrer">
              LinkedIn
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;