import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Hobby = () => {
  const [showArrow, setShowArrow] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const shouldShowArrow = window.scrollY <= 100; // Changer la valeur selon la position où vous voulez cacher la flèche
      setShowArrow(shouldShowArrow);
    };

    window.addEventListener('scroll', handleScroll);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="Page Parallax">
      <div className='divdepart'>
        <img className={`imgarrow ${showArrow ? 'visible' : 'hidden'}`} src="/arerow.png" alt="" />
      </div>
      <div className='hobbycontainer Basket hobbyLeft'>
        <h2>Basket</h2>
      </div>
      <div className='hobbycontainer Espace hobbyright'>
        <h2>Astronomie</h2>
      </div>
      <div className='hobbycontainer Jeux hobbyLeft'>
        <h2>Jeux</h2>
      </div>
      <div className='hobbycontainer Technologie hobbyright'>
        <h2>Technologie</h2>
      </div>
    </div>
  );
};

export default Hobby;