import React, { useState} from 'react';
import { Link } from 'react-router-dom';

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }
  return (
    <div className="menu-container">
      <div className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        ☰
      </div>
      <nav className={`menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li className='menu-item' onClick={toggleMenu}><Link to="/" onClick={scrollToTop}>Accueil</Link></li>
          <li className='menu-item' onClick={toggleMenu}><Link to="/Compétences" onClick={scrollToTop}>Compétences</Link></li>
          <li className='menu-item' onClick={toggleMenu}><Link to="/Formations" onClick={scrollToTop}>Formations</Link></li>
          <li className='menu-item' onClick={toggleMenu}><Link to="/Portfolio" onClick={scrollToTop}>Portfolio</Link></li>
          <li className='menu-item' onClick={toggleMenu}><Link to="/contact" onClick={scrollToTop}>Contact</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;