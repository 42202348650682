import React from 'react';
import { Link } from 'react-router-dom';

const Accueil = () => {
  const items = [
    { title: 'HTML' },
    { title: 'CSS' },
    { title: 'PHP' },
    { title: 'Symfony' },
    { title: 'React' },
    { title: 'ReactNative' },
    { title: 'JavaScript' },
    { title: 'MySQL' },
    { title: 'NodeJS' },
    { title: 'Workflow GitHub' },
    { title: 'WordPress' },
    { title: 'GraphQL' },
  ];
  const ListComponent = ({ items }) => {
    return (
      <ul className="custom-list">
        {items.map((item, index) => (
          <li key={index} className="list-item" data-aos="fade-up" data-aos-duration="1000">
            <div className="blue-dot"></div>
            <p className="list-item-title">{item.title}</p>
          </li>
        ))}
      </ul>
    );
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }
  return (
    <div className="Page Accueil"> 
      <div className="section sectionPresentation">
        <div className="image-container">
        <img src="/illustrationordi.jpg" alt="Représentation d'un personnage" className="image"/>
        </div>
        <div className="vertical-line"></div>
          <div className="text-container">
            <h3>Daunay Alan</h3>
            <p> 
              Bonjour et bienvenue sur mon portfolio en ligne ! Je suis un développeur web full stack passionné par la création d'expériences numériques.
              Mon parcours d'études m'a permis de maîtriser aussi bien le front end que le back end du développement web.
              Avec une solide compréhension des technologies modernes, je suis capable de concevoir des interfaces utilisateur engageantes et fonctionnelles tout en assurant la robustesse et l'efficacité des solutions côté serveur.
            </p>
            <Link to="/Contact" className='button' onClick={scrollToTop}>Contact</Link>
        </div>
      </div>
      <div className="section sectionPortfolio" >
        <div className="text-container text-container-portfolio">
          <h3>Portfolio</h3>
          <p>J'ai eu l'opportunité de travailler sur plusieurs projets passionnants, démontrant ma capacité à transformer des concepts créatifs en produits web concrets,
             que ce soit en travaillant sur le front end avec des frameworks tels que ReactJs ou en développant des API performantes côté serveur tels que GraphQL. <br /> Explorez mon portfolio pour découvrir certains des projets sur lesquels j'ai travaillé et pour vous faire une idée de mes compétences et de ma créativité.</p>
            <Link to="/Portfolio" className='button' onClick={scrollToTop}>En savoir plus +</Link>
        </div>
        <div className="image-container biblio" data-aos="flip-right"  data-aos-duration="1400">
          <img src="/biblio.jpg" alt="Bibliotéque" className="image"/>
        </div>
      </div>
      <div className="section sectionCompetence" >
        <div className="text-container image-container">
          <img src="/illustrationcompétence.png" alt="Représentation d'un personnage" className="image"/>
        </div>
        <div className="text-container text-container-compétences">
          <ListComponent items={items} />
        </div>
        <Link to="/Compétences" className='button buttonCompetences' >En savoir plus +</Link>
      </div>
    </div>
  );
};

export default Accueil;