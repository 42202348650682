import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Remplacez 'Switch' par 'Routes'
import Menu from './composant/Menu';
import Accueil from './composant/Accueil';
import Contact from './composant/Contact';
import Competences from './composant/Competences';
import Portfolio from './composant/Portfolio';

import './App.css'
import Formations from './composant/Formations';
import Hobby from './composant/Hobby';
import Connivence from './composant/projet/Connivence';
import Starblog from './composant/projet/Starblog';
import File from './composant/projet/File';
import Nba from './composant/projet/Nba';
import Discord from './composant/projet/Discord';
import Quizz from './composant/projet/Quizz';

const App = () => {
  return (
    <Router>
      <div>
        <Menu />
        <div>
          <Routes>
            <Route path="/" element={<Accueil />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/compétences" element={<Competences />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/Connivence" element={<Connivence />} />
            <Route path="/Starblog" element={<Starblog />} />
            <Route path="/File" element={<File />} />
            <Route path="/Nba" element={<Nba />} />
            <Route path="/Discord" element={<Discord />} />
            <Route path="/Quizz" element={<Quizz />} />
            <Route path="/formations" element={<Formations />} />
            <Route path="/Hobby" element={<Hobby />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;


//Page Accueil mettre du texte et des vrai images
//Page Hobby/ Ordi Responsive à faire
//Page Contact/Ordi Responsive à faire
//Page Portfolio/ Ordi Responsive à faire
//Page Formations/ Ordi Responsive à faire
