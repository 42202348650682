import React from 'react';

const Formations = () => {
  return (
    <div className="Page diplome-page">
      <div className='divformation'>
        <img className='logoformation wild' src="/logowild.png" alt="" />
        <h3>Concepteur développeur d’application</h3>
        <p>Durée : 12 mois</p>
        <p className='titlelist'>Technologie :</p>
        <ul>
          <li>NodeJs</li>
          <li>GraphQL</li>
          <li>ApolloServer/ApolloClient</li>
          <li>ReactJs</li>
          <li>Javascript</li>
          <li>TypeScript</li>
          <li>Docker</li>
          <li>ReactNative</li>
          <li>Intégration Continue</li>
          <li>Déploiement/Continue</li>
        </ul>
      </div>
      <div className='divformation'>
        <img className='logoformation' src="/logoCefim.png" alt="" />
        <h3>Développeur web et web mobile</h3>
        <p>Durée : 9 mois</p>
        <p className='titlelist'>Technologie :</p>
        <ul>
          <li>Maquettage</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>Javascript</li>
          <li>ReactJs</li>
          <li>MySql</li>
          <li>PHP</li>
          <li>Symfony</li>
          <li>Tests</li>
          <li>Déploiement</li>
        </ul>
      </div>
      <div className='divformation'>
        <img className='logoformation' src="/logoafpp.png" alt="" />
        <h3>Découverte des métiers du numériques</h3>
        <p>Durée : 4 mois</p>
        <p className='titlelist'>Découvertes :</p>
        <ul>
          <li>Développement Web(Html,css,javascript)</li>
          <li>Conception 3D</li>
          <li>Impression 3D</li>
          <li>Montage et démontage d”ordinateur</li>
          <li>Découverte du logiciel aftereffect</li>
          <li>Enquêtes métiers</li>
          <li>Participation au projet (Tours’n’air)</li>
        </ul>
      </div>
    </div>
  );
};

export default Formations;