import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
  return (
    <div className="Page Portfolio">
      <div className='topProjet'> 
          <div className='projet projet-gauche'>
            <h2 className='conniTexte'>Connivence Academy</h2>
          </div>
          <div className='projet projet-droite'>
          <h2>Starblog</h2>
          </div>
      </div>
      <div className='bottomProjet'>
        <div className='projetbas discord'>
        <h3>Bot Discord</h3>
        </div>
        <div className='projetbas file'>
        <h3>Rename File</h3>
        </div>
        <div className='projetbas nba'>
        <h3>NbaStats</h3>
        </div>
        <div className='projetbas quizz'>
        <h3>Multi Quizz</h3>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;