import React from 'react';

const Competences = () => {
  const compétences = [
    { nom: 'HTML', descriptionAnglais:'Design and development of semantic and accessible web pages using HTML5, integration of complex graphic models in HTML for an optimal user experience', description: 'Conception et développement de pages web sémantiques et accessibles en utilisant HTML5, intégration de maquettes graphiques complexes en HTML pour une expérience utilisateur optimale',étoiles:5 },
    { nom: 'CSS', descriptionAnglais:'Advanced styling of interfaces with CSS, ensuring responsive layout and cross-browser compatibility, use of CSS preprocessors such as Sass for efficient style management and simplified maintenance', description: 'Stylisation avancée des interfaces avec CSS, en assurant une mise en page responsive et une compatibilité entre navigateurs, utilisation de préprocesseurs CSS tels que Sass pour une gestion efficace des styles et une maintenance simplifiée',étoiles:4 },
    { nom: 'PHP', descriptionAnglais:'Development of dynamic websites, management of MySQL databases, creation of custom features, performance optimization.', description: 'Développement de sites web dynamiques, gestion de bases de données MySQL, création de fonctionnalités personnalisées, optimisation des performances.',étoiles:3 },
    { nom: 'Symfony', descriptionAnglais:'Development of robust web applications with the Symfony framework, following design and architecture best practices, use of Symfony components to accelerate development and ensure efficient maintenance', description: 'Développement d\'applications web robustes avec le framework Symfony, en suivant les meilleures pratiques de conception et d\'architecture, utilisation de composants Symfony pour accélérer le développement et assurer une maintenance efficace',étoiles:3 },
    { nom: 'React', descriptionAnglais:'Design and implementation of responsive user interfaces, global state management, integration with external APIs, development of reusable components.', description: 'Conception et implémentation d\'interfaces utilisateur réactives, gestion d\'état global, intégration avec des API externes, développement de composants réutilisables.',étoiles:5 },
    { nom: 'ReactNative', descriptionAnglais:'Building cross-platform mobile apps with React Native, delivering native user experience and optimal performance, integrating advanced features such as navigation, push notifications, and access to device features.', description: 'Création d\'applications mobiles multiplateformes avec React Native, offrant une expérience utilisateur native et des performances optimales, intégration de fonctionnalités avancées telles que la navigation, les notifications push, et l\'accès aux fonctionnalités du périphérique.',étoiles:5 },
    { nom: 'JavaScript', descriptionAnglais:'Client-side programming, DOM manipulation, use of frameworks, problem solving and algorithms, performance optimization.', description: 'Programmation côté client, manipulation du DOM, utilisation de frameworks, résolution de problèmes et d\'algorithmes, optimisation des performances.',étoiles:4 },
    { nom: 'MySQL', descriptionAnglais:'MySQL database management, schema design, writing complex queries and performance optimization, implementing relational database management systems for scalable applications.', description: 'Gestion de bases de données MySQL, conception de schémas, écriture de requêtes complexes et optimisation des performances, implémentation de systèmes de gestion de bases de données relationnelles pour des applications évolutives.',étoiles:4 },
    { nom: 'NodeJS', descriptionAnglais:'Developed servers on the backend side with Node.js, harnessed the power of JavaScript on the server side, implemented robust RESTful APIs to enable smooth communication between the frontend and backend.', description: 'Développement de serveurs côté backend avec Node.js, exploitation de la puissance du JavaScript côté serveur, mise en place d\'API RESTful robustes pour permettre une communication fluide entre le frontend et le backend.',étoiles:3 },
    { nom: 'Workflow GitHub', descriptionAnglais:'Efficiently manage development workflow with GitHub, use branches, pull requests and continuous integration, collaborate seamlessly with teams using issue tracking tools and GitHub features.', description: 'Gestion efficace du workflow de développement avec GitHub, utilisation de branches, pull requests et intégration continue, collaboration transparente avec des équipes en utilisant des outils de suivi des problèmes et des fonctionnalités de GitHub.',étoiles:4 },
    { nom: 'WordPress', descriptionAnglais:'Creation and personalization of dynamic and scalable websites with WordPress, implementation of personalized forms, development of tailor-made themes, SEO optimization and configuration of plugins for an optimal user experience.', description: 'Création et personnalisation de sites Web dynamiques et évolutifs avec WordPress, mise en place de formulaire personnaliser, développement de thèmes sur mesure, optimisation SEO et configuration de plugins pour une expérience utilisateur optimale.',étoiles:3 },
    { nom: 'GraphQL', descriptionAnglais:'Design and deployment of advanced GraphQL solutions to improve the efficiency of communication between front-end applications and back-end services. Implementation of flexible schemas, Development of powerful resolvers to manage the overall state of the application, ensuring a responsive and fluid user experience', description: 'Conception et déploiement de solutions GraphQL avancées pour améliorer l`\'efficacité de la communication entre les applications frontales et les services backends. Mise en place de schémas flexibles, Développement de résolveurs puissants pour gérer l`\'état global de l\'application, garantissant une expérience utilisateur réactive et fluide.',étoiles:4 }
  ];
  const CarteCompétence = ({ compétence }) => {
    const [hover, setHover] = React.useState(false);

    const genererEmojisEtoiles = (nombreEtoiles) => {
      return '⭐️'.repeat(nombreEtoiles);
    };

    return (
      <div className={`carte-compétence ${hover ? 'hover' : ''}`} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <h3>{compétence.nom}</h3>
        <p>{genererEmojisEtoiles(compétence.étoiles)}</p>
        {hover && (
          <>
          <div className="hover-content">
            <p>{compétence.description}</p>
          </div>
          <div className={compétence.nom === "HTML"|| compétence.nom ==="Symfony"|| compétence.nom ==="JavaScript"||compétence.nom ==="Workflow GitHub"? "bulle-info-gauche":"bulle-info"}>
          <p>{compétence.descriptionAnglais}</p>
        </div>
        </>
        )}
      </div>
    );
  };

  return (
    <div className='Page Competences'>
    <h2>Mes Expertises Techniques</h2>
    <div className="compétences-container">
      {compétences.map((compétence, index) => (
        <CarteCompétence key={index} compétence={compétence} />
      ))}
    </div>
    </div>
  );
};

export default Competences;