import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Starblog = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Use React Router's navigate function to go back
      };
  return (
    <div className="Page Starblog">
        <button onClick={handleGoBack}>Revenir</button>
      <h2>Starblog</h2>
    </div>
  );
};

export default Starblog;